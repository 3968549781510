import React from 'react';
import { Row } from '../Layout';
import { AmountToPay } from '../AmountToPay';
import { ReferenceIdTextInput } from './ReferenceIdTextInput';

const ExternalLenderPayment = ({ transaction, setTransaction }) => (
  <>
    <Row>
      <AmountToPay transaction={transaction} setTransaction={setTransaction} />
      <ReferenceIdTextInput transaction={transaction} setTransaction={setTransaction} />
    </Row>
  </>
);
export default ExternalLenderPayment;
